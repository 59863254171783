<template>
  <div class="create-loan-application-page">
    <!-- Step 1 -->
    <div v-if="getCurrentState() === 'step1'">
      <Step1 redirect="create-loan-application" />
    </div>
    <!-- Step 1 -->
    <div v-else-if="getCurrentState() === 'step2'">
      <Step2 />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

import Step1 from "@/components/public/pages/create-loan-application/Step1";
import Step2 from "@/components/public/pages/create-loan-application/Step2";

export default {
  name: "LimeLoansCreateLoanApplicationPage",

  components: { Step1, Step2 },

  metaInfo: {
    meta: [
      {
        name: "facebook-domain-verification",
        content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION,
      },
    ],
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  created() {
    if (
      this.auth &&
      this.auth.user &&
      this.auth.user.banking_details &&
      this.auth.user.banking_details.bank_name &&
      this.auth.user.banking_details.account_number &&
      this.auth.user.banking_details.bank_name == "Standard Bank" &&
      this.auth.user.banking_details.account_number == "123456789"
    ) {
      this.$swal({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "Please update your banking details",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      this.$router.push({ name: "DashboardPersonalDataPage" });
    } else if (!this.auth.user.has_banking || !this.auth.user.has_next_of_kin) {
      this.$router.push({ name: "RegistrationPage" });
    }

    this.setDefaultState();

    if (this.auth.user.has_open_application) {
      if (this.auth.user.open_application.has_bank_statement) {
        this.$router.push({ name: "DashboardMyLoanPage" });
      } else {
        this.setCurrentState("step2");
      }
    } else if (this.auth.user.recent_affordability) {
      if (this.auth.user.recent_affordability.requires_update) {
        this.setCurrentState("step1");
      }
    }
  },

  mounted() {},

  methods: {
    ...mapGetters({
      getCurrentState: "createLoanApplication/getCurrentState",
    }),

    ...mapMutations({
      setCurrentState: "createLoanApplication/setCurrentState",
      setDefaultState: "createLoanApplication/setDefaultState",
    }),
  },
};
</script>

<style lang="scss" scoped>
//
</style>
