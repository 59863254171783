<template>
  <div>
    <v-col cols="12" lg="12">
      <v-row>
        <!-- <p class="b-body-1">
          Using one of our trusted partners to supply your bank statements will
          ensure that we receive the correct 3 months bank statements and will
          avoid delays.
        </p>

        <p class="b-body-1">
          <b>
            NOTE 1: PLEASE NOTE THAT YOUR BANKING DETAILS ARE NOT RECORDED BY
            US!</b
          >
        </p> -->
        <p class="b-body-1">
          Please provide your bank statements for the last 3 months
        </p>
      </v-row>
      <v-row>
        <v-col cols="12" lg="5">
          <v-row>
            <v-col cols="12" lg="12">
              <div>
                <div class="d-flex justify-center align-center">
                  <v-img
                    alt="gathr"
                    contain
                    src="@/assets/img/public/pages/home/section-4/GathrLogo.png"
                    max-width="150"
                    height="80"
                  />
                </div>
                <iframe width="900" height="710px" :src="truid.iframeGthrUrl">
                </iframe>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="12" lg="12">
              <div v-if="showIframe">
                <div class="d-flex justify-center align-center">
                  <v-img
                    alt="truid"
                    contain
                    src="@/assets/img/public/pages/home/section-4/truid.svg"
                    max-width="115.71"
                    height="80"
                  />
                </div>
                <iframe width="900" height="710px" :src="truid.iframeUrl">
                </iframe>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <!-- Desktop -->
    <div class="hidden-xs-only">
      <v-btn
        v-if="readyToSubmit"
        @click="nextStep()"
        class="b-button primary text-capitalize mt-3"
        height="48"
        width="175"
        elevation="0"
      >
        Next
      </v-btn>

      <v-btn
        v-else
        @click="nextStep()"
        disabled
        class="b-button primary text-capitalize mt-3"
        height="48"
        width="175"
        elevation="0"
      >
        Next
      </v-btn>
    </div>

    <!-- Mobile -->
    <div class="hidden-sm-and-up">
      <v-btn
        v-if="readyToSubmit"
        @click="nextStep()"
        class="b-button primary text-capitalize mt-3"
        height="48"
        width="100%"
        elevation="0"
      >
        Next
      </v-btn>

      <v-btn
        v-else
        @click="nextStep()"
        disabled
        class="b-button primary text-capitalize mt-3"
        height="48"
        width="100%"
        elevation="0"
      >
        Next
      </v-btn>
    </div>

    <!-- Overlay -->
    <v-overlay :value="overlay" z-index="10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import truid from "@/assets/img/public/pages/home/section-4/truid.svg";
import gathr from "@/assets/img/public/pages/home/section-4/GathrLogo.png";
export default {
  name: "LimeLoansTruidTab",

  data() {
    return {
      overlay: false,
      showIframe: false,
      showIframeGthr: false,
      iframeUrl: "",
      iframeGthrUrl: "",

      // attachment type
      attachmentType: "TruID data JSON",
      attachmentTypeRules: [(v) => !!v || "Required"],
      attachmentTypeHasError: false,
      attachmentTypeErrorMessage: "attachment type",

      // attachment data
      attachmentData: "",
      attachmentDataRules: [(v) => !!v || "Required"],
      attachmentDataHasError: false,
      attachmentDataErrorMessage: "attachment data",

      // file name
      fileName: "",
      fileNameRules: [(v) => !!v || "Required"],
      fileNameHasError: false,
      fileNameErrorMessage: "file name",

      callGetCollectionStatuses: true,
      countDown: 0,
      readyToSubmit: false,
    };
  },

  computed: {
    ...mapState({ auth: "auth", truid: "truid" }),

    ...mapGetters({
      //
    }),
  },

  watch: {
    // attachmentType
    attachmentType() {
      if (this.attachmentTypeHasError) {
        this.attachmentTypeHasError = false;
        this.attachmentTypeErrorMessage = "";
      }
    },

    // attachmentData
    attachmentData() {
      if (this.attachmentDataHasError) {
        this.attachmentDataHasError = false;
        this.attachmentDataErrorMessage = "";
      }
    },

    // fileName
    fileName() {
      if (this.fileNameHasError) {
        this.fileNameHasError = false;
        this.fileNameErrorMessage = "";
      }
    },
  },

  created() {
    this.setDataToSend([]);

    this.setUseOnlyTruid(true);

    if (
      this.truid.iframeUrl != null &&
      this.truid.iframeUrl != "" &&
      this.truid.currentCollection != null &&
      this.truid.currentCollection != ""
    ) {
      console.log("jeremy  " + this.truid.iframeUrl);
      this.iframeUrl = this.truid.iframeUrl;

      this.showIframe = true;

      this.getCollectionStatuses();

      this.initiateCountDownTimer();
    } else {
      this.getIframeUrl();
    }

    // this is for gathr
    this.iframeGthrUrl = "";
    console.log("jeremy1  " + this.truid.iframeGthrUrl);
    this.getIframeGthrUrl();

    // if (
    //   this.truid.iframeGthrUrl != null &&
    //   this.truid.iframeGthrUrl != ""

    // ) {
    //   console.log("jeremy2  " + this.truid.iframeGthrUrl)
    //   this.iframeGthrUrl = this.truid.iframeGthrUrl;

    //   this.showIframeGthr = true;

    // } else {
    //   this.getIframeGthrUrl();
    // }

    // this.$helpers.resetErrorHandlerState();
  },

  mounted() {},

  methods: {
    ...mapActions({
      apiEndpoints: "apiEndpoints/apiEndpoint",

      getApiIframeUrl: "truid/getApiIframeUrl",
      getApiIframeGthrUrl: "truid/getApiIframeGthrUrl",
      getApiCollectionStatuses: "truid/getApiCollectionStatuses",
      getApiProductIndex: "truid/getApiProductIndex",
      getApiTransactionHistory: "truid/getApiTransactionHistory",
      getApiBankStatement: "truid/getApiBankStatement",

      getApiUserDetails: "auth/getApiUserDetails",

      logOutUser: "auth/logOutUser",
    }),

    ...mapGetters({
      //
    }),

    ...mapMutations({
      setUseOnlyTruid: "truid/setUseOnlyTruid",

      setDataToSend: "truid/setDataToSend",
      pushDataToSend: "truid/pushDataToSend",
    }),

    // initiateCountDownTimer
    initiateCountDownTimer() {
      this.countDown = 30;
      this.countDownTimer();

      this.getApiUserDetails();
    },

    // countDownTimer
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();

          // console.log("CS");

          this.getCollectionStatuses();
        }, 10000);
      } else {
        this.callGetCollectionStatuses = false;
        this.readyToSubmit = true;
      }
    },

    // getIframeUrl
    async getIframeUrl() {
      this.overlay = true;

      var data = {
        idNumber: this.auth.idnumber,
        name: this.auth.user.firstnames + " " + this.auth.user.surname,
        mobile: this.auth.user.cellular,
        email: this.auth.user.email,
        bankName: this.auth.user.banking_details.bank_name,
        bankAccount: this.auth.user.banking_details.account_number,
      };

      /* if (
        this.auth.user.banking_details.bank_name == "Standard Bank" &&
        this.auth.user.banking_details.account_number == "123456789"
      ) {
        data = {
          idNumber: process.env.VUE_APP_TRUID_TEST_ID,
          name: process.env.VUE_APP_TRUID_TEST_NAME,
          mobile: process.env.VUE_APP_TRUID_TEST_MOBILE,
          email: process.env.VUE_APP_TRUID_TEST_EMAIL,
          bankName: process.env.VUE_APP_TRUID_TEST_BANK_NAME,
          bankAccount: process.env.VUE_APP_TRUID_TEST_ACCOUNT_NUMBER,
        };
      } */

      const getIframeUrlRes = await this.getApiIframeUrl(data);

      if (getIframeUrlRes.status == 201) {
        this.initiateCountDownTimer();

        this.showIframe = true;
      } else {
        // console.log("1.0");
        this.setUploadManually();
      }

      this.overlay = false;
    },

    async getIframeGthrUrl() {
      this.overlay = true;

      var data = {
        clientReference: this.auth.user.cellular,
        email: this.auth.user.email,
        idNumber: this.auth.idnumber,
        name: this.auth.user.firstnames,
        surname: this.auth.user.surname,
        bank: this.auth.user.banking_details.bank_name,
        bankAccount: this.auth.user.banking_details.account_number,
      };

      const getIframeUrlRes = await this.getApiIframeGthrUrl(data);

      if (getIframeUrlRes.status == 201) {
        this.initiateCountDownTimer();

        this.showIframe = true;
        this.readyToSubmit = true;
      } else {
        // console.log("1.0");
        this.setUploadManually();
      }

      this.overlay = false;
    },

    // getCollectionStatuses
    async getCollectionStatuses() {
      if (!this.callGetCollectionStatuses) {
        return;
      }

      const getCollectionStatusesRes = await this.getApiCollectionStatuses({});

      if (getCollectionStatusesRes.status == 200) {
        const data = getCollectionStatusesRes.data;

        const status = data.find(
          ({ statusCode }) => statusCode === "COLLECT_SUCCESS"
        );

        const statusCancelled = data.find(
          ({ statusCode }) =>
            statusCode === "COLLECT_CANCELLED_USER" ||
            statusCode === "COLLECT_CANCELLED"
        );

        const statusTimedOut = data.find(
          ({ statusCode }) => statusCode === "COLLECT_TIMED_OUT"
        );

        const statusAppApprovalRequired = data.find(
          ({ statusCode }) => statusCode === "COLLECT_APP_APPROVAL_REQUIRED"
        );

        if (status) {
          this.countDown = 0;

          this.getProductIndex();
        } else if (statusCancelled) {
          this.countDown = 0;

          // console.log("2.0");
          this.setUploadManually();
        } else if (statusTimedOut) {
          this.countDown = 0;

          this.setUploadManually();
        } else if (statusAppApprovalRequired) {
          //
        } else {
          // this.$swal({
          //   toast: true,
          //   position: "top-end",
          //   icon: "error",
          //   title: "Please Complete Your Truid Process.",
          //   showConfirmButton: false,
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
        }
      } else {
        // console.log("4.0");
        this.setUploadManually();
      }
    },

    // getProductIndex
    async getProductIndex() {
      this.overlay = true;

      const getProductIndexRes = await this.getApiProductIndex({});

      if (getProductIndexRes.status == 200) {
        const data = getProductIndexRes.data;

        this.readyToSubmit = true;

        if (data.length > 0) {
          var bankTransaction = data.find((product) =>
            product.name.startsWith("transaction_history")
          );

          var bankStatement = data.find((product) =>
            product.name.startsWith("bank_statement")
          );

          if (bankTransaction) {
            await this.getTransactionHistory(bankTransaction);
          }

          if (bankStatement) {
            await this.getBankStatement(bankStatement);
          }

          this.readyToSubmit = true;
        }
      } else {
        // console.log("5.0");
        this.setUploadManually();
      }

      this.overlay = false;
    },

    // getTransactionHistory
    async getTransactionHistory(productIndex) {
      const getTransactionHistoryRes = await this.getApiTransactionHistory({
        productTransactionHistoryId: productIndex.id,
      });

      if (getTransactionHistoryRes.status == 200) {
        const data = getTransactionHistoryRes.data;

        // this.downLoadTheFile(data, productIndex);

        this.fileName = productIndex.name;

        var blob = new Blob([data]);

        this.attachmentType = "TruID data JSON";

        this.attachmentData = await this.$helpers.blobTobase64(blob);

        this.pushDataToSend({
          attachmentType: this.attachmentType,
          attachmentData: this.attachmentData,
          fileName: this.fileName,
        });

        // sendAttachDocument
        await this.sendAttachDocument();
      } else {
        // console.log("6.0");
        this.setUploadManually();
      }
    },

    // getBankStatement
    async getBankStatement(productIndex) {
      const getBankStatementRes = await this.getApiBankStatement({
        productBankStatementId: productIndex.id,
      });

      if (getBankStatementRes.status == 200) {
        const data = getBankStatementRes.data;

        // this.downLoadTheFile(data, productIndex);

        this.fileName = productIndex.name;

        var blob = new Blob([data]);

        this.attachmentType = "Bank statement";

        this.attachmentData = await this.$helpers.blobTobase64(blob);

        this.pushDataToSend({
          attachmentType: this.attachmentType,
          attachmentData: this.attachmentData,
          fileName: this.fileName,
        });

        // sendAttachDocument
        await this.sendAttachDocument();
      } else {
        // console.log("7.0");
        this.setUploadManually();
      }
    },

    // Send Attach Document
    async sendAttachDocument() {
      const sendAttachDocumentRes = await this.apiEndpoints({
        endpoint: "attachment",
        bodyData: {
          attachment_type: this.attachmentType,
          attachment_data: this.attachmentData,
          file_name: this.fileName,
        },
      });

      if (sendAttachDocumentRes.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Your Attach Document Has Been Saved",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else if (sendAttachDocumentRes.status == 401) {
        this.logOutUser();
      } else if (sendAttachDocumentRes.status == 422) {
        const data = sendAttachDocumentRes.data;

        if ("idnumber" in data) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: data.idnumber[0],
          });
        }

        if ("id_number" in data) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: data.id_number[0],
          });
        }

        if ("attachment_type" in data) {
          this.attachmentTypeHasError = true;
          this.attachmentTypeErrorMessage = data.attachment_type[0];
        }

        if ("attachment_data" in data) {
          this.attachmentDataHasError = true;
          this.attachmentDataErrorMessage = data.attachment_data[0];
        }

        if ("file_name" in data) {
          this.fileNameHasError = true;
          this.fileNameErrorMessage = data.file_name[0];
        }
      } else {
        this.$helpers.handleError(
          sendAttachDocumentRes.status,
          sendAttachDocumentRes.data
        );
      }
    },

    // nextStep
    async nextStep() {
      this.overlay = true;

      await this.getApiUserDetails();

      if (
        this.auth &&
        this.auth.user &&
        this.auth.user.open_application
        //&& this.auth.user.open_application.has_truid
      ) {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Your Bank Statement Has Been Saved",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        // sendEvent
        this.sendEvent();

        this.$router.push({ name: "DashboardMyLoanPage" });
      } else {
        this.setUseOnlyTruid(false);

        this.$swal({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: "Complete TruId Process Or Upload Manually Or Send Message.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        await this.getCollectionStatuses();
      }

      this.overlay = false;
    },

    // setUploadManually
    setUploadManually() {
      this.setUseOnlyTruid(false);

      this.$swal({
        toast: true,
        position: "top-end",
        icon: "warning",
        title: "Upload Manually Or Send Message.",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      this.countDown = 0;
    },

    // downLoadTheFile
    downLoadTheFile(data, productIndex) {
      /* if (
        this.auth.user.banking_details.bank_name == "Standard Bank" &&
        this.auth.user.banking_details.account_number == "123456789"
      ) {
        this.fileDownload(data, productIndex.name);
      } */
      this.fileDownload(data, productIndex.name);
    },

    // sendEvent
    sendEvent() {
      try {
        if (this.$gtm.enabled()) {
          window.dataLayer?.push({
            event: "application",
            "event-param": "uploaded_bank_statement",
            "client-id": this.$store.getters["auth/getIdNumber"],
            "loan-count": this.$store.getters["auth/getLoansCount"],
          });
        }
      } catch (error) {
        if (error) {
          //
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//

iframe {
  width: 100%;
  // height: 100%;
}
</style>
