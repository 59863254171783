<template>
  <div class="create-loan-application">
    <div class="step2">
      <v-container class="pa-0 mt-sm-4 mt-md-8">
        <div class="card">
          <v-card
            class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
            elevation="0"
            flat
            tile
          >
            <v-row>
              <v-col cols="12">
                <!-- Create a loan application -->

                <h3 class="heading-h3">Create a loan application</h3>

                <!-- Supporting documents -->
                <p class="b-body-1 mt-1">
                  Step 2 of 2:
                  <span class="b-subtitle-1"> {{ tabInfo }}</span>
                </p>

                <div class="d-flex">
                  <v-card color="primary" width="263" height="4" elevation="0">
                  </v-card>
                  <v-card
                    class="ml-2"
                    color="primary"
                    width="236"
                    height="4"
                    elevation="0"
                  >
                  </v-card>
                </div>
                <h2 class="mt-5 heading-h4">Upload bank statement</h2>

                <v-col class="mt-1" cols="12" lg="12">
                  <v-row>
                    <!-- tabs -->
                    <v-tabs
                      id="tabmenu"
                      class="localfix"
                      active-class="gray800--text"
                      color="primary"
                    >
                      <!-- tabs-slider -->
                      <!-- <v-tabs-slider class="mt-n2"></v-tabs-slider> -->

                      <!-- <v-row> -->
                      <!-- <v-col cols="12" lg="12">
                          
                          <v-tab v-if="has_payslip === false" class="pl-0 pr-0 text-capitalize b-body-1"
                            :key="truid.useOnlyTruid ? 'payslipmessage' : 'send-payslip-message'" :href="truid.useOnlyTruid ? '#payslipmessage' : '#send-payslip-message'
                              " @click="changeTab('send-payslip-message', '#send-payslip-message')">
                            <v-btn class="b-button primary text-capitalize px-8" height="30" elevation="2">L
                              <span class="text-lowercase">atest</span>&nbspP<span
                                class="text-lowercase">ayslip</span>
                            </v-btn>
                          </v-tab>
                        </v-col> -->
                      <!-- <v-col cols="12" lg="3"> -->
                      <!-- Upload automatically -->

                      <v-tab
                        class="pl-0 pr-0 text-capitalize b-body-1"
                        key="upload-automatically"
                        href="#upload-automatically"
                        @click="
                          changeTab(
                            'upload-automatically',
                            '#upload-automatically',
                            'Bank statement'
                          )
                        "
                      >
                        Automatically
                        <!-- <v-btn
                              class="b-button primary text-capitalize px-8"
                              height="30"
                              width="250"
                              elevation="2"
                              >U<span class="text-lowercase"
                                >pload Using Our Partners</span
                              >
                            </v-btn> -->
                      </v-tab>
                      <!-- </v-col> -->

                      <!-- <v-col cols="12" lg="3"> -->
                      <!-- Send USSD message -->
                      <v-tab
                        class="px-3 b-body-1 text-capitalize ml-md-10"
                        :key="
                          truid.useOnlyTruid
                            ? 'ussdmessage'
                            : 'send-ussd-message'
                        "
                        :href="
                          truid.useOnlyTruid
                            ? '#ussdmessage'
                            : '#send-ussd-message'
                        "
                        @click="
                          changeTab(
                            'send-ussd-message',
                            '#send-ussd-message',
                            'I’m Capitec client'
                          )
                        "
                      >
                        I’m<span class="text-lowercase">&nbsp;a&nbsp;</span>Capitec client
                        <!-- <v-btn
                              class="b-button primary text-capitalize px-8"
                              height="30"
                              width="258"
                              elevation="2"
                              >USSD&nbsp;<span class="text-lowercase">for </span
                              ><span class="text-highercase">&nbsp;C</span
                              ><span class="text-lowercase"
                                >apitec clients only</span
                              >
                            </v-btn> -->
                      </v-tab>
                      <!-- </v-col> -->
                      <!-- / W
                            <span class="text-lowercase">hats</span>
                            A<span class="text-lowercase">pp</span> -->
                      <!-- </v-tab> -->

                      <!-- <v-col cols="12" lg="3"> -->
                      <!-- Upload manually -->
                      <v-tab
                        class="px-3 b-body-1 text-capitalize ml-md-10"
                        :key="
                          truid.useOnlyTruid ? 'manually' : 'upload-manually'
                        "
                        :href="
                          truid.useOnlyTruid ? '#manually' : '#upload-manually'
                        "
                        @click="
                          changeTab(
                            'upload-manually',
                            '#upload-manually',
                            'Manually'
                          )
                        "
                      >
                        Manually
                        <!-- <v-btn
                              class="b-button primary text-capitalize px-8"
                              height="30"
                              width="250"
                              elevation="2"
                              >U<span class="text-lowercase"
                                >pload Yourself</span
                              > 
                            </v-btn>-->
                      </v-tab>

                      <!-- </v-col> -->
                      <!-- <v-col cols="12" lg="3">
                         
                          <v-tab
                            class="pl-0 pr-0 text-capitalize b-body-1"
                            :key="
                              truid.useOnlyTruid ? 'message' : 'send-message'
                            "
                            :href="
                              truid.useOnlyTruid ? '#message' : '#send-message'
                            "
                            @click="changeTab('send-message', '#send-message')"
                          >
                            Need Assistance?
                           <v-btn
                              class="b-button primary text-capitalize px-8"
                              height="30"
                              width="250"
                              elevation="2"
                              >N
                              <span class="text-lowercase"
                                >eed Assistance?</span
                              >
                            </v-btn> 

                           
                            <span class="text-lowercase">hats</span>
                            A<span class="text-lowercase">pp</span> 
                          </v-tab>
                        </v-col> -->
                      <!-- </v-row> -->

                      <v-tabs-items v-model="createLoanApplication.step2Tab">
                        <v-tab-item
                          v-if="has_payslip === false"
                          class="mt-4"
                          key="send-payslip-message"
                          value="send-payslip-message"
                        >
                          <v-card max-width="650" flat>
                            <PayslipTab />
                          </v-card>
                        </v-tab-item>

                        <v-tab-item
                          class="mt-4"
                          key="upload-automatically"
                          value="upload-automatically"
                        >
                          <v-card max-width="800" max-height="2400" flat>
                            <!-- TruidTab -->
                            <TruidTab />
                          </v-card>
                        </v-tab-item>

                        <v-tab-item
                          class="mt-4"
                          key="send-ussd-message"
                          value="send-ussd-message"
                        >
                          <p class="b-body-1">
                            <b>DO NOT EMAIL ANY DOCUMENTS DIRECTLY TO</b>
                            ussdbs@lime24.co.za . Follow below USSD prompts on
                            your phone, and Capitec Bank will email us your bank
                            statement directly.
                          </p>
                          <div max-width="850" class="box">
                            <v-col cols="12" lg="4">
                              <v-row>
                                <p class="b-body-1">
                                  <b
                                    ><u
                                      >CLIENTS WITHOUT A CAPITEC BANKING APP</u
                                    ></b
                                  >
                                </p>
                              </v-row>
                              <v-row>
                                <p class="b-body-1">
                                  Step1: Dial <b>*120*3279#</b>
                                </p>
                                <p class="b-body-1">
                                  Step2: Choose option 10 <b>"More"</b>
                                </p>
                                <p class="b-body-1">
                                  Step3: Choose option 3
                                  <b>"e-mail statement"</b>
                                </p>
                                <p class="b-body-1">
                                  Step4: Choose option 1 <b>"accept"</b>
                                </p>
                                <p class="b-body-1">
                                  Step5: Choose option 1 <b>"select account"</b>
                                </p>
                                <p class="b-body-1">
                                  Step6: Choose option 2 <b>"90 Days"</b>
                                </p>
                                <p class="b-body-1">
                                  Step7: Enter email address
                                  <a
                                    href="mailto:ussdbs@lime24.co.za"
                                    target="_blank"
                                  >
                                    ussdbs@lime24.co.za
                                  </a>
                                </p>
                                <p class="b-body-1">
                                  Step8: Enter your <b>"Mobile banking pin"</b>
                                </p>
                              </v-row>
                            </v-col>
                            <v-col cols="12" lg="4">
                              <v-row>
                                <p class="b-body-1">
                                  <b
                                    ><u>CLIENTS WITH A CAPITC BANKING APP</u></b
                                  >
                                </p>
                              </v-row>
                              <v-row>
                                <p class="b-body-1">
                                  Step1: Dial <b>*120*3279# </b>
                                </p>
                                <p class="b-body-1">
                                  Step2: Choose option 8
                                  <b>"account information"</b>
                                </p>

                                <p class="b-body-1">
                                  Step3: Choose option 2
                                  <b>"e-mail statement"</b>
                                </p>
                                <p class="b-body-1">
                                  Step4: Choose option 1 <b>"accept"</b>
                                </p>
                                <p class="b-body-1">
                                  Step5: Choose option 1 <b>"select account"</b>
                                </p>
                                <p class="b-body-1">
                                  Step6: Choose option 2 <b>"90 Days"</b>
                                </p>
                                <p class="b-body-1">
                                  Step7: Enter email address
                                  <a
                                    href="mailto:ussdbs@lime24.co.za"
                                    target="_blank"
                                  >
                                    ussdbs@lime24.co.za
                                  </a>
                                </p>
                                <p class="b-body-1">
                                  Step8: Enter your <b>"Mobile banking pin"</b>
                                </p>
                              </v-row>
                            </v-col>
                          </div>
                        </v-tab-item>

                        <v-tab-item
                          class="mt-4"
                          key="upload-manually"
                          value="upload-manually"
                        >
                          <v-card max-width="650" flat>
                            <FilesTab />
                          </v-card>
                        </v-tab-item>

                        <v-tab-item
                          class="mt-4"
                          key="send-message"
                          value="send-message"
                        >
                          <v-card max-width="650" flat>
                            <p class="b-body-1">
                              To avoid any delays, ensure you email us your
                              correct latest 3-months bank statements showing
                              your <b>LAST 3 SALARY DEPOSITS</b>.
                            </p>
                            <p class="b-body-1">
                              <b>
                                NOTE 1: USE ONLY YOUR ID NUMBER IN THE SUBJECT
                                LINE OF YOUR EMAIL.</b
                              >
                            </p>
                            <p class="b-body-1">
                              <b> NOTE 2 :</b>bank statements downloaded from
                              your online banking or banking App in PDF Format
                              will be accepted.
                              <b>NO SCANNED DOCUMENTS ALLOWED!</b>
                            </p>
                            <p class="b-body-1">
                              <b> NOTE 3 : </b>We do not accept bank statements
                              from Tyme Bank, Discovery Bank, Access Bank and
                              Old Mutual Bank.
                            </p>

                            <p class="b-body-1">
                              <b>EMAIL STATEMENTS TO</b>
                              <a
                                href="mailto:documents@lime24.co.za"
                                target="_blank"
                              >
                                documents@lime24.co.za</a
                              >
                            </p>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-tabs>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import FilesTab from "@/components/public/pages/create-loan-application/tabs/FilesTab";
import TruidTab from "@/components/public/pages/create-loan-application/tabs/TruidTab";
import PayslipTab from "@/components/public/pages/create-loan-application/tabs/PayslipTab";

export default {
  name: "LimeLoansStep2",

  components: { FilesTab, TruidTab, PayslipTab },

  data() {
    return {
      tab: null,

      selectedTab: 0,
      tabInfo: "Bank statement",

      tab: "upload-automatically",
      key: "upload-automatically",
      href: "#upload-automatically",

      // tab: "send-ussd-message",
      // key: "send-ussd-message",
      // href: "#send-ussd-message",

      isTruid: false,
      has_payslip: true,
      heightvalue: "48px",
      hideslider: true,
    };
  },

  computed: {
    ...mapState({
      createLoanApplication: "createLoanApplication",
      truid: "truid",
    }),
    //

    ...mapGetters({
      //
    }),
  },

  created() {
    this.setUseOnlyTruid(false);
    // this.getClientDetails();

    if (this.has_payslip == false) {
      this.setStep2Tab("send-payslip-message");
      this.setStep2Key("send-payslip-messagee");
      this.setStep2Href("#send-payslip-message");
    } else {
      this.setStep2Tab("upload-automatically");
      this.setStep2Key("upload-automatically");
      this.setStep2Href("#upload-automatically");
      // this.setStep2Tab("send-ussd-message");
      // this.setStep2Key("send-ussd-message");
      // this.setStep2Href("#send-ussd-message");
    }

    if (window.innerWidth <= 1025) {
      this.heightvalue = "200px";
    } else {
      this.heightvalue = "48px";
    }
    console.log(window.innerWidth);
    // window.setInterval(() => {
    //   this.allowAccessToAllTabs();
    // }, 3000);
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    ...mapActions({
      getApiUserDetails: "auth/getApiUserDetails",
    }),

    ...mapMutations({
      setUseOnlyTruid: "truid/setUseOnlyTruid",
      setStep2Tab: "createLoanApplication/setStep2Tab",
      setStep2Key: "createLoanApplication/setStep2Key",
      setStep2Href: "createLoanApplication/setStep2Href",
    }),

    ...mapGetters({
      getUser: "auth/getUser",
    }),

    // changeTab
    changeTab(key, href, info) {
      this.setStep2Key(key);
      this.setStep2Href(href);

      // console.log($('.v-tabs-bar'))
      // if (!this.truid.useOnlyTruid) {
      this.tab = key;
      this.setStep2Tab(key);

      this.tabInfo = info;
      // }

      // console.log("this.key", this.key);
      // console.log("this.href", this.href);
    },

    // allowAccessToAllTabs
    allowAccessToAllTabs() {
      this.setUseOnlyTruid(false);

      // console.log("this.truid.useOnlyTruid", this.truid.useOnlyTruid);
    },

    getClientDetails() {
      this.getApiUserDetails();
      var details = this.getUser();
      this.has_payslip = details.open_application.has_payslip;
      console.log("payslip = " + this.has_payslip);
    },

    onResize() {
      if (window.innerWidth <= 1025) {
        this.heightvalue = "200px";
      } else {
        this.heightvalue = "48px";
      }
      console.log(window.innerWidth);
    },

    // startTimer() {
    //   var timer = setInterval(function () {
    //     alert("5 seconds are up");
    //   }, 5000);
    // },
  },
};
</script>

<style lang="scss" scoped>
a {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}
.box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

// @media screen and (max-width: 426px) {
//   #tabmenu {
//     height:200px;
//   }

// }
</style>
