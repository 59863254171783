import { render, staticRenderFns } from "./PayslipTab.vue?vue&type=template&id=79579cc3&scoped=true&"
import script from "./PayslipTab.vue?vue&type=script&lang=js&"
export * from "./PayslipTab.vue?vue&type=script&lang=js&"
import style0 from "./PayslipTab.vue?vue&type=style&index=0&id=79579cc3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79579cc3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VForm,VImg,VOverlay,VProgressCircular,VSpacer})
