<template>
  <div>
    <!-- <p class="b-body-1">
      NB! Ensure your Bank Statements shows your <b>LAST 3 SALARY DEPOSITS</b>!
    </p>
    <p class="b-body-1">
      <b>
        NOTE 1: </b>Only bank statements downloaded from your online banking or
        banking App in PDF Format will be accepted. <b>NO SCANNED DOCUMENTS ACCEPTED!</b
      >
    </p>
    <p class="b-body-1">
      <b>
        NOTE 2: </b>We do not accept bank statements from Tyme Bank, Discovery Bank,
        Access Bank and Old Mutual Bank!
      >
    </p>
    <p class="b-body-1">
      <b>
        NOTE 3: </b>FNB & Nedbank clients to provide transactional history statement of current month if latest salary does not appear on the bank statements.
      >
    </p> -->

    <p class="b-body-1 mt-3">
      Please provide your last 3 month bank statements. Upload your scan or
      photo of bank statements here
    </p>
    <v-form
      ref="formAttachDocument"
      v-model="validFormAttachDocument"
      lazy-validation
    >
      <v-card max-width="428" flat>
        <v-card
          class="transparent border-radius"
          width="100%"
          max-width="428"
          flat
        >
          <div
            class="custom-files"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <input
              type="file"
              multiple
              name="fields[assetsFieldHandle][]"
              id="assetsFieldHandle"
              class="hidden"
              @change="onChange"
              ref="file"
              accept=".pdf,.jpg,.jpeg,.gif,.png"
            />

            <label for="assetsFieldHandle" class="label">
              <div class="d-flex align-start">
                <!-- clip -->
                <v-img
                  alt="clip"
                  class="clip"
                  contain
                  src="@/assets/img/public/pages/create-loan-application/files/clip.png"
                  max-width="24"
                />

                <div class="ml-3">
                  <!-- Select up to 5 files -->
                  <h5 class="heading-h5 primary--text">Add document</h5>
                  <p class="b-body-2 gray700--text">
                    Jpg, png, gif, pdf up to 2 Mb
                  </p>
                </div>
              </div>
            </label>

            <v-card class="transparent" width="100%" max-width="428" flat tile>
              <ul class="mt-n3 ml-n6 mb-n3" v-if="filelist.length" v-cloak>
                <!-- <v-card class="transparent" width="100%" flat tile> -->
                <li class="" v-for="(file, index) in filelist" :key="index">
                  <div class="d-flex align-start">
                    <div class="d-flex align-start">
                      <!-- check-circle-l -->
                      <v-img
                        alt="check-circle-l"
                        class="check-circle-l"
                        contain
                        src="@/assets/img/public/pages/create-loan-application/files/check-circle-l.png"
                        max-width="24"
                      />

                      <p class="b-body-1 ml-2">
                        {{ file.name }} ({{
                          (file.size / (1024 * 1024)).toFixed(2)
                        }}MB)
                      </p>
                    </div>

                    <v-spacer />

                    <button
                      class="ml-2"
                      type="button"
                      @click="remove(filelist.indexOf(file))"
                      title="Remove file"
                    >
                      <!-- times -->
                      <v-img
                        alt="times"
                        class="times"
                        contain
                        src="@/assets/img/public/pages/create-loan-application/files/times.png"
                        max-width="24"
                      />
                    </button>
                  </div>
                </li>
                <!-- </v-card> -->
              </ul>
            </v-card>
          </div>
        </v-card>

        <p v-if="filelistHasError" class="b-body-1 error600--text">
          {{ filelistErrorMessage }}
        </p>
      </v-card>

      <!-- Next -->

      <!-- Desktop -->
      <!-- readyToSubmit -->
      <div class="hidden-xs-only">
        <v-btn
          v-if="readyToSubmit"
          @click="validateFormAttachDocument()"
          class="b-button primary text-capitalize mt-8"
          height="48"
          width="175"
          elevation="0"
        >
          Next
        </v-btn>

        <v-btn
          v-else
          class="b-button primary text-capitalize mt-8"
          height="48"
          width="175"
          elevation="0"
          disabled
        >
          Next
        </v-btn>
      </div>

      <!-- Mobile -->
      <div class="hidden-sm-and-up">
        <v-btn
          v-if="readyToSubmit"
          @click="validateFormAttachDocument()"
          class="b-button primary text-capitalize mt-8"
          height="48"
          width="100%"
          elevation="0"
        >
          Next
        </v-btn>

        <v-btn
          v-else
          class="b-button primary text-capitalize mt-8"
          height="48"
          width="100%"
          elevation="0"
          disabled
        >
          Next
        </v-btn>
      </div>
    </v-form>

    <!-- Overlay -->
    <v-overlay :value="overlay" z-index="10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

export default {
  name: "LimeLoansFilesTab",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  delimiters: ["${", "}"], // Avoid Twig conflicts

  data() {
    return {
      overlay: false,
      readyToSubmit: false,

      // files
      files: null,
      filesRules: [(v) => !!v || "Required"],

      formAttachDocument: false,
      validFormAttachDocument: false,

      base64: "",

      //   attachment
      attachment: null,
      attachmentRules: [(v) => !!v || "Required"],
      attachmentHasError: false,
      attachmentErrorMessage: "attachment",

      // attachment type
      attachmentType: "Bank statement",
      attachmentTypeRules: [(v) => !!v || "Required"],
      attachmentTypeHasError: false,
      attachmentTypeErrorMessage: "attachment type",

      // attachment data
      attachmentData: "",
      attachmentDataRules: [(v) => !!v || "Required"],
      attachmentDataHasError: false,
      attachmentDataErrorMessage: "attachment data",

      // file name
      fileName: "",
      fileNameRules: [(v) => !!v || "Required"],
      fileNameHasError: false,
      fileNameErrorMessage: "file name",

      filelist: [], // Store our uploaded files
      filelistHasError: false,
      filelistErrorMessage: "Only a max of 5 files is allowed",
      filesToSubmit: [],
      file: {},
    };
  },

  created() {
    this.$helpers.resetErrorHandlerState();
  },

  watch: {
    // attachment
    attachment() {
      if (this.attachmentHasError) {
        this.attachmentHasError = false;
        this.attachmentErrorMessage = "";
      }
    },

    // attachmentType
    attachmentType() {
      if (this.attachmentTypeHasError) {
        this.attachmentTypeHasError = false;
        this.attachmentTypeErrorMessage = "";
      }
    },

    // attachmentData
    attachmentData() {
      if (this.attachmentDataHasError) {
        this.attachmentDataHasError = false;
        this.attachmentDataErrorMessage = "";
      }
    },

    // fileName
    fileName() {
      if (this.fileNameHasError) {
        this.fileNameHasError = false;
        this.fileNameErrorMessage = "";
      }
    },
  },

  computed: {
    ...mapState({ auth: "auth" }),

    ...mapGetters({
      getAttachmentTypesList: "list/getAttachmentTypesList",
    }),
  },

  mounted() {},

  methods: {
    ...mapActions({
      getApiUserDetails: "auth/getApiUserDetails",

      logOutUser: "auth/logOutUser",
    }),

    ...mapGetters({
      getIdNumber: "auth/getIdNumber",
      getSessionKey: "auth/getSessionKey",
      getDocumentData: "document/getDocumentData",
    }),

    ...mapMutations({
      //
    }),

    // validateFormAttachDocument
    async validateFormAttachDocument() {
      // this.$refs.formAttachDocument.validate();

      this.overlay = true;

      if (this.readyToSubmit) {
        // this.sendAttachDocument();

        var allowedToSubmit = true;

        // get files data
        for (const file of this.filelist) {
          var type = file.type.split("/").pop();

          // Check if the files are correct
          if (
            !["jpeg", "jpg", "png", "gif", "pdf"].includes(type.toLowerCase())
          ) {
            this.readyToSubmit = false;

            this.filelistHasError = true;
            this.filelistErrorMessage =
              "A file type of " + type + " is not allowed!";
            allowedToSubmit = false;
          }
        }

        if (allowedToSubmit) {
          // var filesToSubmit = [];

          for (let i = 0; i < this.filelist.length; i += 1) {
            // var fileToSubmit = {};

            await this.getBase64(this.filelist[i]).then((data) => {
              var base64 = data;

              var attachment_data = base64
                .replace("data:", "")
                .replace(/^.+,/, "");

              // fileToSubmit = {
              //   attachment_data: attachment_data,
              //   file_name: this.filelist[i].name,
              //   submitted: false,
              // };

              this.attachmentData = attachment_data;
              this.fileName = this.filelist[i].name;

              // console.log(i + 1);
            });

            // console.log(i + 1);
            await this.sendAttachDocument();

            // console.log("this.attachmentData", this.attachmentData);
            // console.log("this.fileName", this.fileName);
          }

          // console.log("this.file ", this.file);
          // console.log("filesToSubmit", filesToSubmit);
          // console.log("len", filesToSubmit.length);
          // console.log("filesToSubmit", this.filesToSubmit);

          // this.filesToSubmit = filesToSubmit;

          // console.log("this.filesToSubmit", this.filesToSubmit);
          // this.sendAttachDocuments();

          await this.getUserDetails();
        }

        // this.overlay = false;
      }
    },

    // Send Attach Documents
    async sendAttachDocuments() {
      // this.overlay = true;

      // var filesToSubmitTemp = { ...this.filesToSubmit };

      // for (const file of filesToSubmitTemp) {
      //   console.log(file);
      // }

      /*  for (let i = 0; i < this.filesToSubmit.length; i += 1) {
        if (typeof this.filesToSubmit[i] === "object") {
          try {
            this.filesToSubmit[i] = JSON.parse(
              JSON.stringify(this.filesToSubmit[i])
            );
          } catch (e) {
            console.error(e);
          }
        }
      } */

      // console.log(...this.filesToSubmit);

      // console.log("9fgsdbnu");
      // console.log(this.filelist);
      // console.log("9fgsdbnu");
      // console.log(this.filesToSubmit);
      // console.log("9fgsdbnu");
      // console.log(...this.filesToSubmit);
      // console.log("filesToSubmitTemp", filesToSubmitTemp);
      // console.log(this.filelist.length);

      // console.log("file 5", this.filesToSubmit[4]);
      // console.log("file 4", this.filesToSubmit[3]);
      // console.log("file 3", this.filesToSubmit[2]);
      // console.log("file 2", this.filesToSubmit[1]);
      // console.log("file 1", this.filesToSubmit[0]);
      // Send File
      if (this.filelist.length == 5) {
        // file 5
        this.file = this.filesToSubmit[4];
        // console.log("file", this.filesToSubmit[4]);
        await this.sendAttachDocument();

        // file 4
        this.file = this.filesToSubmit[3];
        // console.log("file", this.file);
        await this.sendAttachDocument();

        // file 3
        this.file = this.filesToSubmit[2];

        // console.log("file", this.file);
        await this.sendAttachDocument();

        // console.log("file", this.file);

        // file 2
        this.file = this.filesToSubmit[1];
        await this.sendAttachDocument();

        // console.log("file", this.file);

        // file 1
        this.file = this.filesToSubmit[0];
        // console.log("file", this.file);
        await this.sendAttachDocument();
      } else if (this.filelist.length == 4) {
        // file 4
        this.file = this.filesToSubmit[3];
        // console.log("file", this.file);
        await this.sendAttachDocument();

        // file 3
        this.file = this.filesToSubmit[2];
        // console.log("file", this.file);
        await this.sendAttachDocument();

        // file 2
        this.file = this.filesToSubmit[1];
        // console.log("file", this.file);
        await this.sendAttachDocument();

        // file 1
        this.file = this.filesToSubmit[0];
        // console.log("file", this.file);
        await this.sendAttachDocument();
      } else if (this.filelist.length == 3) {
        // file 3
        this.file = this.filesToSubmit[2];
        // console.log("file", this.file);
        await this.sendAttachDocument();

        // file 2
        this.file = this.filesToSubmit[1];
        // console.log("file", this.file);
        await this.sendAttachDocument();

        // file 1
        this.file = this.filesToSubmit[0];
        // console.log("file", this.file);
        await this.sendAttachDocument();
      } else if (this.filelist.length == 2) {
        // file 2
        this.file = this.filesToSubmit[1];
        // console.log("file", this.file);
        await this.sendAttachDocument();

        // file 1
        this.file = this.filesToSubmit[0];
        // console.log("file", this.file);
        await this.sendAttachDocument();
      } else if (this.filelist.length == 1) {
        // file 1
        this.file = this.filesToSubmit[0];
        // console.log("file", this.file);
        await this.sendAttachDocument();
      }

      await this.getUserDetails();

      // this.overlay = false;
    },

    // Send Attach Document
    async sendAttachDocument() {
      // this.overlay = true;

      // console.log("this.attachmentData", this.attachmentData);
      // console.log("this.fileName", this.fileName);

      try {
        const res = await this.axios.post("attachment", {
          idnumber: this.getIdNumber(),
          session_key: this.getSessionKey(),
          attachment_type: this.attachmentType,
          attachment_data: this.attachmentData,
          file_name: this.fileName,
        });

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Your Attach Document Has Been Saved",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.id_number[0],
            });
          }

          if ("attachment_type" in data) {
            this.attachmentTypeHasError = true;
            this.attachmentTypeErrorMessage = data.attachment_type[0];
          }

          if ("attachment_data" in data) {
            this.attachmentDataHasError = true;
            this.attachmentDataErrorMessage = data.attachment_data[0];
          }

          if ("file_name" in data) {
            this.fileNameHasError = true;
            this.fileNameErrorMessage = data.file_name[0];
          }
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      // this.overlay = false;
    },

    async getUserDetails() {
      await this.getApiUserDetails();

      this.$router.push({ name: "DashboardMyLoanPage" });

      this.overlay = false;
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    // onChange
    onChange() {
      this.filelist = this.filelist.concat([...this.$refs.file.files]);

      this.filelist = this.filelist.slice(0, 5);

      this.$refs.file.files = null;

      this.validateFiles();
    },

    // remove
    remove(i) {
      this.filelist.splice(i, 1);

      this.$refs.file.files = null;

      this.validateFiles();
    },

    // dragover
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },

    // dragleave
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },

    // drop
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },

    // validateFiles
    validateFiles() {
      var totalFilesSize = 0;

      this.readyToSubmit = false;

      for (const file of this.filelist) {
        totalFilesSize += file.size;
      }

      if (this.filelist.length > 5) {
        this.filelistHasError = true;
        this.filelistErrorMessage = "Only a max of 5 files is allowed";
      } else if (totalFilesSize > 2_097_152) {
        this.filelistHasError = true;
        this.filelistErrorMessage = "Only a max of 2mb files size is allowed";
      } else {
        this.filelistHasError = false;

        if (this.filelist.length > 0) {
          this.readyToSubmit = true;
        }
      }
    },

    // sendEvent
    sendEvent() {
      try {
        if (this.$gtm.enabled()) {
          window.dataLayer?.push({
            event: "application",
            "event-param": "uploaded_bank_statement",
            "client-id": this.$store.getters["auth/getIdNumber"],
            "loan-count": this.$store.getters["auth/getLoansCount"],
          });
        }
      } catch (error) {
        if (error) {
          //
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}

.custom-files {
  /* Uploader */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 12px 8px 12px;
  gap: 8px;

  width: 100%;

  /* Neutral/00 */

  background: #ffffff;
  /* Neutral/500 */

  border: 1px dashed #ee0505;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  .hidden {
    display: none;
  }

  .label {
    cursor: pointer;
  }

  ul {
    list-style-type: none;
  }
}

.border-radius {
  border-radius: 8px;
}

::file-selector-button {
  display: none;
}
</style>
