<template>
  <div class="create-loan-application">
    <v-container class="pa-0 mt-sm-4 mt-md-8">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-form ref="formStep1" lazy-validation>
            <v-row>
              <v-col cols="12">
                <!-- Create a loan application -->

                <h3 class="heading-h3">Create a loan application</h3>

                <!-- Consumer expense declaration -->
                <p class="b-body-1 mt-1">
                  Step 1 of 2:
                  <span class="b-subtitle-1">Consumer expense declaration</span>
                </p>

                <div class="d-flex">
                  <v-card color="primary" width="263" height="4" elevation="0">
                  </v-card>
                  <v-card
                    class="ml-2"
                    color="gray400"
                    width="236"
                    height="4"
                    elevation="0"
                  >
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" lg="4">
                <v-row>
                  <!-- Select the desired amount -->
                  <v-col cols="12">
                    <h5 class="heading-h4">Select the desired amount</h5>
                  </v-col>

                  <!-- Loan amount -->
                  <v-col cols="12">
                    <v-card max-width="362" flat>
                      <!-- Loan Amount Display -->
                      <div class="d-flex">
                        <h6 class="heading-h6">Loan amount</h6>
                        <v-spacer />
                        <h6 class="heading-h6">
                          R {{ createLoanApplication.loanAmount }}
                        </h6>
                      </div>

                      <!-- Slider with Plus and Minus Buttons -->
                      <div class="d-flex align-center mt-3">
                        <!-- Minus Button -->
                        <v-btn
                          icon
                          @click="
                            createLoanApplication.loanAmount = Math.max(
                              minPrice,
                              createLoanApplication.loanAmount - interval
                            )
                          "
                        >
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>

                        <!-- Slider -->
                        <div style="flex-grow: 1; padding: 0 10px">
                          <vue-slider
                            v-model="createLoanApplication.loanAmount"
                            :min="minPrice"
                            :max="maxPrice"
                            :interval="interval"
                            dotSize="20"
                            tooltip="none"
                          />
                        </div>

                        <!-- Plus Button -->
                        <v-btn
                          icon
                          @click="
                            createLoanApplication.loanAmount = Math.min(
                              maxPrice,
                              createLoanApplication.loanAmount + interval
                            )
                          "
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>

                      <!-- Min and Max Price -->
                      <div class="d-flex">
                        <p class="b-body-2 gray700--text">R {{ minPrice }}</p>
                        <v-spacer />
                        <p class="b-body-2 gray700--text">R {{ maxPrice }}</p>
                      </div>
                    </v-card>
                  </v-col>

                  <!-- Loan period Section -->
                  <v-col cols="12">
                    <v-card max-width="362" flat>
                      <!-- Days -->
                      <div class="d-flex">
                        <h6 class="heading-h6">Loan period</h6>
                        <v-spacer />
                        <h6 class="heading-h6">{{ days }} days</h6>
                      </div>

                      <!-- Slider with Plus and Minus Buttons -->
                      <div class="d-flex align-center mt-3">
                        <!-- Minus Button -->
                        <v-btn icon @click="decreaseDays">
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>

                        <!-- Slider -->
                        <div style="flex-grow: 1; padding: 0 10px">
                          <vue-slider
                            v-model="days"
                            :min="minDays"
                            :max="maxDays"
                            dotSize="20"
                            tooltip="none"
                          />
                        </div>

                        <!-- Plus Button -->
                        <v-btn icon @click="increaseDays">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>

                      <!-- Min and Max Duration -->
                      <div class="d-flex">
                        <p class="b-body-2 gray700--text">{{ minDays }} days</p>
                        <v-spacer />
                        <p class="b-body-2 gray700--text">{{ maxDays }} days</p>
                      </div>
                    </v-card>
                  </v-col>
                  <!-- <span class="b-body-1 accent--text font-weight-bold">
                    R {{ amountRepayable }}
                  </span> -->

                  <!-- Income, R -->
                  <v-col cols="12" class="mt-5">
                    <h5 class="heading-h4 mt-n5">Income, R</h5>
                  </v-col>

                  <!-- Gross Monthly Income  -->
                  <v-col cols="12">
                    <v-card class="mt-n3" max-width="362" flat>
                      <p class="label-text mb-1">Gross monthly</p>

                      <v-text-field
                        v-model="grossMonthlyIncome"
                        :rules="grossMonthlyIncomeRules"
                        class="inputPrice"
                        type="number"
                        height="34px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col>

                  <!-- Net Monthly Income -->
                  <v-col cols="12">
                    <v-card class="mt-n4" width="362" flat>
                      <p class="label-text mb-1">Net monthly</p>

                      <v-text-field
                        v-model="netMonthlyIncome"
                        :rules="netMonthlyIncomeRules"
                        class="inputPrice"
                        type="number"
                        height="34px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col>

                  <!-- Expenses, R -->
                  <v-col cols="12" class="mt-5">
                    <h5 class="heading-h4 mt-n5">Expenses, R</h5>
                  </v-col>

                  <!-- Medical Expenses -->
                  <v-col cols="12">
                    <v-card class="mt-n3" width="362" flat>
                      <p class="label-text mb-1">Medical</p>

                      <v-text-field
                        v-model="createLoanApplication.medicalExpense"
                        :rules="medicalExpenseRules"
                        class="inputPrice"
                        type="number"
                        height="34px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col>

                  <!-- Accomodation Expenses -->
                  <v-col cols="12">
                    <v-card class="mt-n4" width="362" flat>
                      <p class="label-text mb-1">
                        Accomodation, excl. bond repayment
                      </p>

                      <v-text-field
                        v-model="createLoanApplication.accomodationExpense"
                        :rules="accomodationExpenseRules"
                        class="inputPrice"
                        type="number"
                        height="34px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col>

                  <!-- Groceries And Household Expenses -->
                  <v-col cols="12">
                    <v-card class="mt-n4" width="362" flat>
                      <p class="label-text mb-1">Groceries and household</p>

                      <v-text-field
                        v-model="
                          createLoanApplication.groceriesAndHouseholdExpense
                        "
                        :rules="groceriesAndHouseholdExpenseRules"
                        class="inputPrice"
                        type="number"
                        height="34px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col>

                  <!-- Transport Expenses -->
                  <v-col cols="12">
                    <v-card class="mt-n4" width="362" flat>
                      <p class="label-text mb-1">
                        Transport, excl. car repayment
                      </p>

                      <v-text-field
                        v-model="createLoanApplication.transportExpense"
                        :rules="transportExpenseRules"
                        class="inputPrice"
                        type="number"
                        height="34px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col>

                  <!-- Education Expenses -->
                  <v-col cols="12">
                    <v-card class="mt-n4" width="362" flat>
                      <p class="label-text mb-1">Education</p>

                      <v-text-field
                        v-model="createLoanApplication.educationExpense"
                        :rules="educationExpenseRules"
                        class="inputPrice"
                        type="number"
                        height="34px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col>

                  <!-- Maintenance Expenses -->
                  <v-col cols="12">
                    <v-card class="mt-n4" width="362" flat>
                      <p class="label-text mb-1">Maintenance</p>

                      <v-text-field
                        v-model="createLoanApplication.maintenanceExpense"
                        :rules="maintenanceExpenseRules"
                        class="inputPrice"
                        type="number"
                        height="34px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col>

                  <!-- Water And Electricity Expenses -->
                  <v-col cols="12">
                    <v-card class="mt-n4" width="362" flat>
                      <p class="label-text mb-1">Water and electricity</p>

                      <v-text-field
                        v-model="
                          createLoanApplication.waterAndElectricityExpense
                        "
                        :rules="waterAndElectricityExpenseRules"
                        class="inputPrice"
                        type="number"
                        height="34px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col>

                  <!-- Loan Repayments Expenses -->
                  <!-- <v-col cols="12">
                    <v-card class="mt-n4" width="362" flat>
                      <p class="label-text mb-1">Loan repayments</p>

                      <v-text-field
                        v-model="createLoanApplication.loanRepaymentExpense"
                        :rules="loanRepaymentExpenseRules"
                        class="inputPrice"
                        type="number"
                        height="34px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col> -->

                  <!-- Disposable income -->
                  <v-col cols="12">
                    <v-card class="mt-n4" width="362" flat>
                      <p class="label-text mb-1">Disposable income</p>

                      <h3 class="heading-h3">
                        <span
                          v-if="
                            isNaN(createLoanApplication.indicativeDisposable)
                          "
                        >
                          –
                        </span>

                        <span
                          v-else-if="
                            createLoanApplication.indicativeDisposable !== null
                          "
                        >
                          {{ createLoanApplication.indicativeDisposable }}
                        </span>

                        <span v-else>–</span>
                      </h3>

                      <p
                        v-if="
                          !isNaN(createLoanApplication.indicativeDisposable) &&
                          createLoanApplication.indicativeDisposable !== null &&
                          createLoanApplication.indicativeDisposable <= 500
                        "
                        class="error600--text b-body-2"
                      >
                        Disposable income should be more than R 500
                      </p>
                    </v-card>
                    <h4 v-if="readyToSubmit" class="red--text ml-1 mt-3">
                      Please update if you disagree or "Next" to accept.
                    </h4>
                  </v-col>

                  <!-- Next -->
                  <v-col cols="12" class="mt-n4">
                    <v-btn
                      @click="validateFormStep1"
                      class="b-button primary text-capitalize"
                      height="48"
                      width="175"
                      elevation="0"
                    >
                      Next
                    </v-btn>

                    <!-- <v-btn
                      v-if="readyToSubmit"
                      @click="validateFormStep1"
                      class="b-button primary text-capitalize"
                      height="48"
                      width="175"
                      elevation="0"
                    >
                      Next
                    </v-btn>

                    <v-btn
                      v-else
                      @click="validateFormStep1"
                      class="b-button gray500 text-capitalize"
                      height="48"
                      width="175"
                      elevation="0"
                      disabled
                    >
                      Next
                    </v-btn> -->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>

          <!-- Overlay -->
          <v-overlay :value="overlay" z-index="1000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "LimeLoansStep1",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,
      readyToSubmit: false,

      days: 10,
      minDays: 3,
      maxDays: 90,
      daysStep: 1,
      amountRepayable: 1246.5,

      minPrice: 500,
      maxPrice: 8000,
      price: 1700,
      interval: 100,
      loanDuration: 22,
      minDuration: 3,
      maxDuration: 90,

      expensesHasError: true,
      expensesErrorMessage: "expenses",

      // Medical Expense
      medicalExpenseRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Medical Expenses should be R0 or above",
      ],
      medicalExpenseHasError: false,
      medicalExpenseErrorMessage: "medical expense",

      // Accomodation Expense
      accomodationExpenseRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Accomodation Expenses should be R0 or above",
      ],
      accomodationExpenseHasError: false,
      accomodationExpenseErrorMessage: "accomodation expense",

      // Groceries And Household Expense
      groceriesAndHouseholdExpenseRules: [
        (v) => !!v || "Required",
        (v) =>
          (v && v >= 0) ||
          "Groceries And Household Expenses should be R0 or above",
      ],
      groceriesAndHouseholdExpenseHasError: false,
      groceriesAndHouseholdExpenseErrorMessage:
        "Groceries And Household expense",

      // Transport Expense
      transportExpenseRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Transport Expenses should be R0 or above",
      ],
      transportExpenseHasError: false,
      transportExpenseErrorMessage: "transport expense",

      // Education Expense
      educationExpenseRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Education Expenses should be R0 or above",
      ],
      educationExpenseHasError: false,
      educationExpenseErrorMessage: "education expense",

      // Maintenance Expense
      maintenanceExpenseRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Maintenance Expenses should be R0 or above",
      ],
      maintenanceExpenseHasError: false,
      maintenanceExpenseErrorMessage: "maintenance expense",

      // Water And Electricity Expense
      waterAndElectricityExpenseRules: [
        (v) => !!v || "Required",
        (v) =>
          (v && v >= 0) ||
          "Water And Electricity Expenses should be R0 or above",
      ],
      waterAndElectricityExpenseHasError: false,
      waterAndElectricityExpenseErrorMessage: "water and electricity expense",

      // Loan Repayment Expense
      loanRepaymentExpenseRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Loan Repayment Expenses should be R0 or above",
      ],
      loanRepaymentExpenseHasError: false,
      loanRepaymentExpenseErrorMessage: "loan repayment expense",

      // Gross Monthly Income
      grossMonthlyIncome: "",
      grossMonthlyIncomeRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Gross Monthly Income should be R0 or above",

        (v) =>
          (v && this.isGrossGreaterthanNetMonthlyIncome) ||
          "Gross income should be more than net monthly income",
      ],
      grossMonthlyIncomeHasError: false,
      grossMonthlyIncomeErrorMessage: "Gross Monthly Income",

      // Net Monthly Income
      // netMonthlyIncome: false,
      netMonthlyIncome: "",
      netMonthlyIncomeRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Net Monthly Income should be R0 or above",
        (v) => (v && v > 5000) || "Net monthly income should be more R 5 000",
      ],
      netMonthlyIncomeHasError: false,
      netMonthlyIncomeErrorMessage: "Net Monthly Income",

      isGrossGreaterthanNetMonthlyIncome: false,

      incomeHasError: true,
      incomeErrorMessage: "income",

      // idNumber
      idNumberHasError: false,
      idNumberErrorMessage: "id number",

      // loanAmount
      loanAmountHasError: false,
      loanAmountErrorMessage: "loan amount",
    };
  },

  computed: {
    ...mapState({
      createLoanApplication: "createLoanApplication",
      marketing: "marketing",
    }),
  },

  created() {
    this.$helpers.resetErrorHandlerState();
  },

  mounted() {},

  watch: {
    // loanAmount
    "createLoanApplication.loanAmount"(val) {
      this.setLoanAmount(val);

      this.setReadyToSubmit();
    },

    // medicalExpense
    "createLoanApplication.medicalExpense"(val) {
      this.setMedicalExpense(val);

      this.calculateIndicativeDisposable();

      this.setReadyToSubmit();
    },

    // accomodationExpense
    "createLoanApplication.accomodationExpense"(val) {
      this.setAccomodationExpense(val);

      this.calculateIndicativeDisposable();

      this.setReadyToSubmit();
    },

    // groceriesAndHouseholdExpense
    "createLoanApplication.groceriesAndHouseholdExpense"(val) {
      this.setGroceriesAndHouseholdExpense(val);

      this.calculateIndicativeDisposable();

      this.setReadyToSubmit();
    },

    // transportExpense
    "createLoanApplication.transportExpense"(val) {
      this.setTransportExpense(val);

      this.calculateIndicativeDisposable();

      this.setReadyToSubmit();
    },

    // educationExpense
    "createLoanApplication.educationExpense"(val) {
      this.setEducationExpense(val);

      this.calculateIndicativeDisposable();

      this.setReadyToSubmit();
    },

    // maintenanceExpense
    "createLoanApplication.maintenanceExpense"(val) {
      this.setMaintenanceExpense(val);

      this.calculateIndicativeDisposable();

      this.setReadyToSubmit();
    },

    // waterAndElectricityExpense
    "createLoanApplication.waterAndElectricityExpense"(val) {
      this.setWaterAndElectricityExpense(val);

      this.calculateIndicativeDisposable();

      this.setReadyToSubmit();
    },

    // loanRepaymentExpense
    "createLoanApplication.loanRepaymentExpense"(val) {
      this.setLoanRepaymentExpense(val);

      this.calculateIndicativeDisposable();

      this.setReadyToSubmit();
    },

    // grossMonthlyIncome
    "createLoanApplication.grossMonthlyIncome"(val) {
      this.setGrossMonthlyIncome(val);

      this.calculateIndicativeDisposable();

      this.setReadyToSubmit();
    },

    // grossMonthlyIncome
    grossMonthlyIncome(val) {
      this.setGrossMonthlyIncome(val);

      // this is where we do the NCR minimum expenses
      this.calculateNCRMinimumLivingExpenses() 
      //**************/

      this.calculateIndicativeDisposable();

      this.compareIncomes();

      this.setReadyToSubmit();
    },

    // netMonthlyIncome
    "createLoanApplication.netMonthlyIncome"(val) {
      this.setNetMonthlyIncome(val);

      this.calculateIndicativeDisposable();

      this.setReadyToSubmit();
    },

    // netMonthlyIncome
    netMonthlyIncome(val) {
      this.setNetMonthlyIncome(val);

      this.calculateIndicativeDisposable();

      this.compareIncomes();

      this.setReadyToSubmit();
    },
  },

  methods: {
    ...mapActions({
      logOutUser: "auth/logOutUser",
    }),

    ...mapGetters({
      getNetMonthlyIncome: "createLoanApplication/getNetMonthlyIncome",

      getIdNumber: "auth/getIdNumber",
      getSessionKey: "auth/getSessionKey",
      getSessionExpires: "auth/getSessionExpires",

      marketingCheckSessionExpired: "marketing/checkSessionExpired",
    }),

    ...mapMutations({
      setCurrentState: "createLoanApplication/setCurrentState",
      setLoanAmount: "createLoanApplication/setLoanAmount",

      setMedicalExpense: "createLoanApplication/setMedicalExpense",
      setAccomodationExpense: "createLoanApplication/setAccomodationExpense",
      setGroceriesAndHouseholdExpense:
        "createLoanApplication/setGroceriesAndHouseholdExpense",
      setTransportExpense: "createLoanApplication/setTransportExpense",
      setEducationExpense: "createLoanApplication/setEducationExpense",
      setMaintenanceExpense: "createLoanApplication/setMaintenanceExpense",
      setWaterAndElectricityExpense:
        "createLoanApplication/setWaterAndElectricityExpense",
      setLoanRepaymentExpense: "createLoanApplication/setLoanRepaymentExpense",

      setGrossMonthlyIncome: "createLoanApplication/setGrossMonthlyIncome",
      setNetMonthlyIncome: "createLoanApplication/setNetMonthlyIncome",

      setIndicativeDisposable: "createLoanApplication/setIndicativeDisposable",

      setUser: "auth/setUser",

      resetMarketingState: "marketing/resetState",
    }),

    // decreaseDays
    decreaseDays() {
      if (this.days > this.minDays) {
        this.days -= this.daysStep;
      }
    },

    // increaseDays
    increaseDays() {
      if (this.days < this.maxDays) {
        this.days += this.daysStep;
      }
    },

    // validateFormStep1
    validateFormStep1() {
      this.$refs.formStep1.validate();

      if (this.$refs.formStep1.validate()) {
        this.sendNewApplication();
      }
    },

    // setNextState
    setNextState() {
      this.setCurrentState("step2");

      // sendEvent
      this.sendEvent();

      this.$vuetify.goTo(0);
    },

    calculateNCRMinimumLivingExpenses() {

      var NCRlivingExpensesAmount = 0
      if(this.createLoanApplication.grossMonthlyIncome !== null){
        if(this.createLoanApplication.grossMonthlyIncome >= 0 && this.createLoanApplication.grossMonthlyIncome <= 800.00){
          NCRlivingExpensesAmount = 0 + (((this.createLoanApplication.grossMonthlyIncome - 0) * 9) / 100)
        }
        if(this.createLoanApplication.grossMonthlyIncome >= 800.01 && this.createLoanApplication.grossMonthlyIncome <= 6250.00){
          NCRlivingExpensesAmount = 800.00 + (((this.createLoanApplication.grossMonthlyIncome - 800.01) * 9) / 100)
        }
        if(this.createLoanApplication.grossMonthlyIncome >= 6250.01 && this.createLoanApplication.grossMonthlyIncome <= 25000.00){
          NCRlivingExpensesAmount = 1167.88 + (((this.createLoanApplication.grossMonthlyIncome - 6250.01) * 9) / 100)
        }
        if(this.createLoanApplication.grossMonthlyIncome >= 25000.01 && this.createLoanApplication.grossMonthlyIncome <= 50000){
          NCRlivingExpensesAmount = 2855.38 + (((this.createLoanApplication.grossMonthlyIncome - 25000.01) * 9) / 100)
        }
        if(this.createLoanApplication.grossMonthlyIncome >= 50000.01){
          NCRlivingExpensesAmount = 4905.38 + (((this.createLoanApplication.grossMonthlyIncome - 50000.01) * 9) / 100)
        } 
      }

      this.createLoanApplication.medicalExpense = Math.ceil(((Math.round((NCRlivingExpensesAmount * 5) / 100)) + 1) / 10) * 10
      this.createLoanApplication.accomodationExpense = Math.ceil(((Math.round((NCRlivingExpensesAmount * 40) / 100)) + 1) / 10) * 10
      this.createLoanApplication.groceriesAndHouseholdExpense = Math.ceil(((Math.round((NCRlivingExpensesAmount * 30) / 100)) + 1) / 10) * 10
      this.createLoanApplication.transportExpense = Math.ceil(((Math.round((NCRlivingExpensesAmount * 15) / 100)) + 1) / 10) * 10
      this.createLoanApplication.educationExpense = 1
      this.createLoanApplication.maintenanceExpense = 1
      this.createLoanApplication.waterAndElectricityExpense = Math.ceil(((Math.round((NCRlivingExpensesAmount * 10) / 100)) + 1) / 10) * 10
      this.createLoanApplication.loanRepaymentExpense = 0
            
    },

    calculateIndicativeDisposable() {
      if (
        this.createLoanApplication.medicalExpense !== null &&
        this.createLoanApplication.accomodationExpense !== null &&
        this.createLoanApplication.groceriesAndHouseholdExpense !== null &&
        this.createLoanApplication.transportExpense !== null &&
        this.createLoanApplication.educationExpense !== null &&
        this.createLoanApplication.maintenanceExpense !== null &&
        this.createLoanApplication.waterAndElectricityExpense !== null &&
        this.createLoanApplication.loanRepaymentExpense !== null &&
        this.createLoanApplication.grossMonthlyIncome !== null &&
        this.createLoanApplication.netMonthlyIncome !== null
      ) {
        this.setIndicativeDisposable();
      }
    },

    // setReadyToSubmit
    setReadyToSubmit() {
      if (
        this.createLoanApplication.medicalExpense === "" ||
        this.createLoanApplication.medicalExpense === null ||
        this.createLoanApplication.accomodationExpense === "" ||
        this.createLoanApplication.accomodationExpense === null ||
        this.createLoanApplication.groceriesAndHouseholdExpense === "" ||
        this.createLoanApplication.groceriesAndHouseholdExpense === null ||
        this.createLoanApplication.transportExpense === "" ||
        this.createLoanApplication.transportExpense === null ||
        this.createLoanApplication.educationExpense === "" ||
        this.createLoanApplication.educationExpense === null ||
        this.createLoanApplication.maintenanceExpense === "" ||
        this.createLoanApplication.maintenanceExpense === null ||
        this.createLoanApplication.waterAndElectricityExpense === "" ||
        this.createLoanApplication.waterAndElectricityExpense === null ||
        this.createLoanApplication.loanRepaymentExpense === "" ||
        this.createLoanApplication.loanRepaymentExpense === null ||
        this.createLoanApplication.grossMonthlyIncome === "" ||
        this.createLoanApplication.grossMonthlyIncome === null ||
        this.createLoanApplication.netMonthlyIncome === "" ||
        (this.createLoanApplication.netMonthlyIncome === null &&
          this.createLoanApplication.indicativeDisposable > 500) ||
        !this.$refs.formStep1.validate()
      ) {
        this.readyToSubmit = false;
      } else {
        this.readyToSubmit = true;
      }
    },

    // compareIncomes
    compareIncomes() {
      if (this.grossMonthlyIncome != "" && this.netMonthlyIncome != "") {
        this.isGrossGreaterthanNetMonthlyIncome =
          parseInt(this.grossMonthlyIncome) > parseInt(this.netMonthlyIncome);

        if (!this.isGrossGreaterthanNetMonthlyIncome) {
          this.$refs.formStep1.validate();
        }
      } else {
        this.isGrossGreaterthanNetMonthlyIncome = true;
      }
    },

    // Send New Application
    async sendNewApplication() {
      this.overlay = true;

      try {
        // incomes
        const incomes = [
          {
            description: "Salary (gross)",
            amount: parseInt(this.createLoanApplication.grossMonthlyIncome),
          },
          {
            description: "Salary (net)",
            amount: parseInt(this.createLoanApplication.netMonthlyIncome),
          },
          // {
          //   description: "Indicative Disposable",
          //   amount: parseInt(this.createLoanApplication.indicativeDisposable),
          // },
        ];

        // expenses
        const expenses = [
          {
            description: "Medical",
            amount: parseInt(this.createLoanApplication.medicalExpense),
          },
          {
            description: "Accomodation, excl. bond repayment",
            amount: parseInt(this.createLoanApplication.accomodationExpense),
          },
          {
            description: "Groceries And Household",
            amount: parseInt(
              this.createLoanApplication.groceriesAndHouseholdExpense
            ),
          },
          {
            description: "Transport, excl. car repayment",
            amount: parseInt(this.createLoanApplication.transportExpense),
          },
          {
            description: "Education",
            amount: parseInt(this.createLoanApplication.educationExpense),
          },
          {
            description: "Maintenance",
            amount: parseInt(this.createLoanApplication.maintenanceExpense),
          },
          {
            description: "Water And Electricity",
            amount: parseInt(
              this.createLoanApplication.waterAndElectricityExpense
            ),
          },
          {
            description: "Loan repayments",
            amount: parseInt(this.createLoanApplication.loanRepaymentExpense),
          },
        ];

        var reqData = {
          idnumber: this.getIdNumber(),
          session_key: this.getSessionKey(),
          loan_amount: this.createLoanApplication.loanAmount,
          incomes,
          expenses,
        };

        if (this.marketingCheckSessionExpired()) {
          this.resetMarketingState();

          this.$cookies.remove("traffic_source_data");
        } else {
          reqData = await this.addMarketingToReq(reqData);
        }

        // console.log("reqData", reqData);

        const res = await this.axios.post("new-application", reqData);

        if (res.status == 200) {
          this.getUserDetail();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Your New Application Has Been Submitted",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          this.resetMarketingState();

          this.$cookies.remove("traffic_source_data");
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          // console.log(data);

          if ("idnumber" in data) {
            this.idNumberHasError = true;
            this.idNumberErrorMessage = data.idnumber[0];

            this.$swal({
              icon: "error",
              title: "Oops...",
              text: this.idNumberErrorMessage,
            });
          } else if ("loan_amount" in data) {
            this.loanAmountHasError = true;
            this.loanAmountErrorMessage = data.loan_amount[0];

            this.$swal({
              icon: "error",
              title: "Oops...",
              text: this.loanAmountErrorMessage,
            });
          }
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },

    // Get User Detail
    async getUserDetail() {
      this.overlay = true;

      try {
        const res = await this.axios.post("detail", {
          idnumber: this.getIdNumber(),
          session_key: this.getSessionKey(),
        });

        if (res.status == 200) {
          const data = res.data;

          this.setUser(data);

          this.setNextState();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },

    // addMarketingToReq
    async addMarketingToReq(reqData) {
      // lead_id
      if (this.marketing.lead_id != "") {
        reqData.lead_id = this.marketing.lead_id;
      }

      // utm_source
      if (this.marketing.utm_source != "") {
        reqData.utm_source = this.marketing.utm_source;

        reqData.ip_address = await this.getIPAddress();
        reqData.user_agent = await this.getUserAgent();
      }

      // utm_medium
      if (this.marketing.utm_medium != "") {
        reqData.utm_medium = this.marketing.utm_medium;
      }

      // utm_campaign
      if (this.marketing.utm_campaign != "") {
        reqData.utm_campaign = this.marketing.utm_campaign;
      }

      // utm_content
      if (this.marketing.utm_content != "") {
        reqData.utm_content = this.marketing.utm_content;
      }

      // utm_term
      if (this.marketing.utm_term != "") {
        reqData.utm_term = this.marketing.utm_term;
      }

      // webmaster_id
      if (this.marketing.webmaster_id != "") {
        reqData.webmaster_id = this.marketing.webmaster_id;
      }

      // clickid
      if (this.marketing.clickid != "") {
        reqData.clickid = this.marketing.clickid;
      }

      // gclid
      if (this.marketing.gclid != "") {
        reqData.gclid = this.marketing.gclid;
      }

      // mindbox_click_id
      if (this.marketing.mindbox_click_id != "") {
        reqData.mindbox_click_id = this.marketing.mindbox_click_id;
      }

      return reqData;
    },

    // getIPAddress
    async getIPAddress() {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        const ipAddress = data.ip;

        return ipAddress;
      } catch (error) {
        console.error("Error fetching IP address: " + error.message);

        return "127.0.0.1";
      }
    },

    // getUserAgent
    getUserAgent() {
      return navigator.userAgent;
    },

    // sendEvent
    sendEvent() {
      try {
        if (this.$gtm.enabled()) {
          window.dataLayer?.push({
            event: "application",
            "event-param": "apply_for_loan",
            "client-id": this.$store.getters["auth/getIdNumber"],
            "loan-count": this.$store.getters["auth/getLoansCount"],
          });
        }
      } catch (error) {
        if (error) {
          //
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.heading-h6 {
  //styleName: Header mobile/H3;
  font-family: "Raleway";
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
}
</style>
